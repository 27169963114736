
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './data/useListApData';
import useVisible from '@/hooks/useVisible';
import auditAccrued from './components/auditAccrued.vue';
import xhAuditAccrued from './components/xhAuditAccrued.vue';
import importDeal from './components/importDeal.vue';
import { erpConfirm, errorMessage, errorTip, exportFile, loadPage } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import { httpPost } from '@/api';
import router from '@/router';

export default defineComponent({
  name: 'accruedCope',
  components: { ErpList, auditAccrued, xhAuditAccrued, importDeal },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const rowId = ref('');
    const customParams: any = ref({
      arapType: 2,
    });
    const data = reactive({
      detailsData: null,
      version: '',
      orderArapId: '',
      improtVisible: false,
      category: 1,
    });

    // 路由跳转
    const handleCommand = (path) => {
      router.push(path);
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    const exportInFile = () => {
      data.category = -1;

      data.improtVisible = true;
      showVisible();
    };
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/orderArap/exportArapList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    const auditForm = async (row) => {
      const res = await httpPost('/malicrm/orderArap/queryOrderArapDetailById', {
        orderArapId: row.orderArapId,
        arapType: 2,
      });

      if (res.code === 200) {
        data.detailsData = res.result;
        data.version = res.result.version;
        data.orderArapId = row.orderArapId;
        data.improtVisible = false;
        data.category = res.result.category;
        showVisible();
      } else {
        errorTip(res.message);
      }
    };
    // 查看详情
    const rowClick = (row, column) => {
      const { label } = column;
      if (label !== '操作') {
        if (row.category === 1) {
          loadPage('detailCope', {
            id: row.orderArapId,
            version: row.version,
          });
        } else {
          loadPage('xhDetailCope', {
            id: row.orderArapId,
            version: row.version,
          });
        }
      }
    };

    return {
      handleCommand,
      ...toRefs(data),
      exportInFile,
      auditForm,
      exportFileFn,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      rowId,
    };
  },
});
